import {
    Box,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    VStack,
} from "@chakra-ui/react";
import Logo from "../Navbar/Logo";
import EmailSignIn from "./EmailSignIn";
import GoogleSignIn from "./GoogleSignIn";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    closable?: boolean;
}

const AuthModalContent = ({ isOpen, onClose, closable = true }: Props) => {
    const isUserAgentInstagramOrWhatsapp =
        navigator.userAgent.includes("Instagram") ||
        navigator.userAgent.includes("WhatsApp");

    return (
        <Modal
            isOpen={isOpen}
            onClose={closable ? onClose : () => {}}
            size={"xl"}
        >
            <ModalOverlay backdropFilter={"auto"} backdropBlur={"md"} />
            <ModalContent
                zIndex={1000}
                bgColor={"secondary.500"}
                color="white"
                top={"10%"}
            >
                <ModalHeader
                    textAlign={"center"}
                    borderBottomWidth={1}
                    w="full"
                    borderColor="gray.700"
                >
                    Ingresa o registrate para continuar
                </ModalHeader>
                {closable && <ModalCloseButton />}
                <ModalBody justifyContent={"center"} display={"flex"}>
                    <VStack
                        spacing={10}
                        w="60%"
                        minW={"fit-content"}
                        alignItems={"stretch"}
                        p={5}
                        py={10}
                    >
                        <EmailSignIn />
                        <GoogleSignIn
                            isDisabled={isUserAgentInstagramOrWhatsapp}
                        />
                        {isUserAgentInstagramOrWhatsapp && (
                            <Text p={5} fontSize="sm" textAlign={"center"}>
                                Tu navegador actual no soporta la autenticación
                                con Google (es posible que estés usando uno
                                integrado en otra aplicación). Por favor, abre
                                el sitio en el navegador de tu dispositivo si
                                quieres autenticarte con Google.
                            </Text>
                        )}
                    </VStack>
                </ModalBody>
                <ModalFooter
                    display={"flex"}
                    justifyContent={"center"}
                    borderColor={"gray.700"}
                    borderTopWidth={1}
                >
                    <Box h={"1.8rem"} filter={"grayscale(100%)"}>
                        <Logo />
                    </Box>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default AuthModalContent;
