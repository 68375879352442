export interface FlyerUpdate {
    id?: string;
    isVideo: boolean;
    dataUri?: string;
    order: number;
    url?: string;
    isNew: boolean;
}

export enum ProductType {
    Ticket,
    Consumption,
    Table,
}

export interface Sort {
    field: string;
    isAscending: boolean;
}

export enum PurchasePaymentMethod {
    MercadoPago,
    Cash,
    Uala,
}

export enum EventStatus {
    Active,
    InSuspension,
    Suspended,
}

export enum PurchaseStatus {
    Stale,
    Approved,
    Refunded,
    RefundFailed,
}

export enum FeatureEnum {
    WhatsApp,
}

export enum Country {
    Argentina,
    Bolivia,
    Chile,
    Colombia,
    CostaRica,
    Ecuador,
    Guatemala,
    Mexico,
    Panama,
    Paraguay,
    Peru,
    Uruguay,
}
